.topBar{
    background: url('./img/tapbar.png') no-repeat;
    background-size: 100%;
    height: 64px;
    box-shadow: 0px 4px 4px 0px rgba(42,30,111,0.45); 
}
.topBar .content{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topBar .content .left{
    display: flex;
}
.topBar .content .left .logo-wrap{
    height: 64px;
    margin-right: 24px;
}
.topBar .content .left .logo-wrap img{
    height: 100%;
    width: 100%;
}
.topBar .content .left .menus{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.topBar .content .left .menus .item{
    padding: 22px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    letter-spacing: 2px;
}

.topBar .content .left .menus .item:hover,.topBar .content .left .menus .item.active{
    background: linear-gradient(180deg,rgba(13,13,13,0.00) 70%, rgba(121,97,255,0.40));
}

.topBar .content .left .menus .item.active{
    color:#7F68FF;
    font-weight: medium;
}
i{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
i.index{
    background-image: url('./img/icon/index.png');
}
i.order{
    background-image: url('./img/icon/order.png');
}
i.invite{
    background-image: url('./img/icon/invite.png');
}
i.notic{
    background-image: url('./img/icon/notic.png');
}
i.receivelist{
    background-image: url('./img/icon/receivelist.png');
}
i.shop{
    background-image: url('./img/icon/shop.png');
}
.topBar .content .left .menus .item.active i.index{
    background-image: url('./img/icon/index_active.png');
}
.topBar .content .left .menus .item.active i.order{
    background-image: url('./img/icon/order_active.png');
}
.topBar .content .left .menus .item.active i.invite{
    background-image: url('./img/icon/invite_active.png');
}
.topBar .content .left .menus .item.active i.notic{
    background-image: url('./img/icon/notic_active.png');
}
.topBar .content .left .menus .item.active i.receivelist{
    background-image: url('./img/icon/receivelist_active.png');
}
.topBar .content .left .menus .item.active i.shop{
    background-image: url('./img/icon/shop_active.png');
}

.topBar .content .right .button{
    background: #7F68FF;
    padding: 9px 40px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;
}
.topBar .content .right .button:hover{
    background: #a190ff;
}
.topBar .content .right .button:active{
    background: #6248F3;
}
.topBar .userinfo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.topBar .userinfo .left{
    color: #F0F0F0;
    flex-direction: column;
    
}
.topBar .userinfo .left p{
    text-align: end;
    margin: 0;
    font-size: 14px;
    padding: 0;
}
.popover div{
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}
.popover div:hover{
    background: #403D4D;
}
.popover div:active{
    background: transparent;
    color:#76757A;
}
i.person{
    background-image: url("./img/person.png");
    width: 24px;
    height: 24px;
}
.popover div:active i.person{
    background-image: url("./img/person_active.png");
}
i.exit{
    background-image: url("./img/exit.png");
    width: 24px;
    height: 24px;
}
.popover div:active i.exit{
    background-image: url("./img/exit_active.png");
}

.ant-popover .ant-popover-inner{
    padding: 9px 0;
}

@media (max-width:790px) {
    .topBar .content{
        width: 100vw;
    }
}