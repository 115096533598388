.step-wrap{
    .title{
        text-align: center;
        margin-top: 32px;
        margin-bottom: 12px;
    }
    .steps{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #222;
        padding: 24px 0;
        gap:50px;
        border-radius: 4px;
        .item{
            text-align: center;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color:#FFFFFF;
            .image-wrap{
                width: 72px;
                height: 72px;
                margin-bottom: 8px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .inner{
            height: 20px;
            img{
                height: 100%;
            }
        }
        
    }
}
@media (max-width:790px) {
    .step-wrap{
        margin:0 15px;
        .title{
            margin-top: 12px;
            margin-bottom: 8px;
        }
        .steps{
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            gap:5px;
            padding-left: 22px;
            padding-top: 8px;
            padding-bottom: 16px;
            .item{
                font-size: 12px;
                width: 96px;
                .image-wrap{
                    width: 40px;
                    height: 40px;
                }
                p{
                    margin: 0;
                    padding: 0;
                }
            }
            .inner
            {
                height: 9px;
                margin-top: 10px;
            }
        }
    }
}