.shops-wrap{
    padding: 12px;
    .shop{
        background: #222222;
        padding: 12px;
        border-radius: 4px;
        margin-bottom: 12px;
        .top-info{
            display: flex;
            gap:4px;
            .image-wrap{
                width: 36px;
                height: 36px;
                img{
                    width: 100%;
                }
            }
            .shop-info{
                h3{
                    font-size: 12px;
                    margin: 0;
                }
                .desc{
                    font-size: 10px;
                    color: #aeadb4;
                    margin: 0;
                    margin-top: 5px;
                }
            }
            
        }
        .order-info{
            font-size: 12px;
            color:#F0F0F0;
            display: flex;
            justify-content: space-between;
        }
        .weapon-list{
            display: flex;
            gap: 8px;
            width: 100%;
            .weapon{
                width: calc(33% - 4px);
                .image-wrap{
                    width: 100%;
                    height: 69px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: url('../img/item-bg.png') no-repeat;
                    background-size: 100% 100%;
                    img{
                        height: 90%;
                    }
                }
                .weapon-info{
                    padding: 4px;
                    border: 1px solid #4e4d4f;
                    border-radius: 2px;
                    border-top: none;
                    .name{
                        font-size: 10px;
                        margin: 0;
                        margin-left: 2px;
                        color:#F0F0F0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .price{
                        margin: 0;
                        font-size: 12px;
                        color: #7f68ff;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            .btn{
                color:#7F68FF;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
            }
        }
    }
}
.goodslist{
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 15px;
    flex-wrap: wrap;
    .weapon{
        width: calc(33% - 20px);
        .image-wrap{
            width: 100%;
            height: 69px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../img/item-bg.png') no-repeat;
            background-size: 100% 100%;
            img{
                height: 90%;
            }
        }
        .weapon-info{
            padding: 4px;
            border: 1px solid #4e4d4f;
            border-radius: 2px;
            border-top: none;
            .name{
                font-size: 10px;
                margin: 0;
                margin-left: 2px;
                color:#F0F0F0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .price{
                margin: 0;
                font-size: 12px;
                color: #7f68ff;
            }
        }
    }
}
.page-wrap{
    text-align: center;
}