.weaponlist{
    .item{
        display: flex;
        align-items: center;
        gap: 8px;
        .img-wrap{
            width: 96px;
            height: 64px;
            background:url("./img/item-bg.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 80%;
            }
        }
        border-bottom: 1px solid #333333;
        padding: 12px 0;
        &:last-child{
            border-bottom: none;
        }
    }
}
.confirm-btns{
    display: flex;
    justify-content: center;
    gap: 20px;
    .btn{
        padding: 0 36px;
        height: 40px;
        font-size: 16px;
    }
}
.confirm-title{
    font-size: 14px;
    color: #f0f0f0;
    font-weight: 500 ;
    padding: 0;
    margin: 0;
    i.warning{
        background-image: url('./img/warning.png');
    }
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}
.confirm-content{
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
}
.confirm-tips{
    font-size: 14px;
    color: #f0f0f0;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 20px;
}
.action-btns{
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
    padding: 2px 0;
    .btn{
        height: 28px;
        padding: 0 15px;
    }
}

.ant-btn-default.ant-btn-background-ghost:hover{
    color: #AEADB4 !important;
    background: #403d4d !important;
    border-color: #aeadb4 !important;
}
.ant-btn-default.ant-btn-background-ghost:active{
    color:#76757A !important;
    background: transparent !important;
}