.userinfo-wrap{
    display: flex;
    gap:16px;
    .balance-wrap{
        background: #222222;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        width: 190px;
        i.balance{
            background-image: url('./img/balance.png');
            width: 72px;
            height: 72px;
        }
        p{
            font-size: 12px;
            margin: 0;
        }
        .money{
            margin: 0;
            color: #FFFFFF;
            font-size: 14px;
        }
    }
    
    .userinfo{
        padding: 16px 24px;
        background: #222222;
        border-radius: 4px;
        width: 992px;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        .info-item{
            display: flex;
            align-items: center;
            gap: 8px;
            label{
                font-size: 14px;
                color:#AEADB4;
                width: 56px;
            }
            .info-content{
                padding: 12px;
                background: #333333;
                border-radius: 4px;
                font-size: 14px;
                display: flex;
                width: 219px;
                justify-content: space-between;
                .text{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 75%;
                }
                .btn{
                    color:#7F68FF;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    cursor: pointer;
                    &:hover{
                        opacity: .8;
                    }
                    &:active{
                        opacity: .9;
                    }
                }
                i.edit{
                    background-image: url('./img/edit.png');
                }
                i.copy{
                    background-image: url('./img/copy.png');
                }
            }
        }
    }
}

