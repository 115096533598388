.noitc-item{
    background: #222222;
    border-radius: 4px;
    padding: 16px 24px;
    margin-bottom: 15px;
    cursor: pointer;
    h3{
        color:#F0F0F0;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: normal;
    }
    >div{
        background-color: #111111;
        padding: 12px;
        display: flex;
        margin-top: 12px;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        font-size: 14px;
        color:#AEADB4;
        border: 1px solid #333333;
        border-radius: 4px;
    }
    i.da{
        background-image: url('./da.png');
    }
}
@media (max-width:790px) {
    .notic-wrap-web{
        margin: 12px 15px;
        .noitc-item{
            padding: 8px;
        }
    }
}