.Footer{
    background: #1a191b;
    color: #8C8B90;
    font-size: 12px;
    padding: 24px 0;
}
.Footer .content{
    width: 1200px;
    margin: 0 auto;
}
.Footer .content .top{
    display:  flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    
}

.Footer .content .top .menu{
    display: flex;
    gap:80px;
}
.Footer .content .top .menu .item a{
    color: #8C8B90;
}
.Footer .content .top p{
    margin: 0;
    padding: 0;
}
.Footer .content .line{
    height: 1px;
    border-bottom: 2px dashed #2E2E2E;
    margin: 16px 0;
}
.Footer .content .copy{
    display: none;
}

@media (max-width:790px) {
    .Footer .content{
        width: 100vw;
        text-align: center;
    }
    .Footer .content .top .menu{
        width: 100%;
        gap: 8px;
        justify-content: space-around;
    }
    .Footer .content .top .copyright{
        display: none;
    }
    .Footer .content .copy{
        display: block;
    }
    .Footer .content .top{
        justify-content: center;
    }
}