.myDialog{
    color:#AEADB4;
    .ant-modal-content{
        background: #222222;
        border-radius: 4px;
        margin-bottom: 12px;
    }
    .ant-modal-header{
        background: transparent;
        text-align: center;
        .title{
            font-size: 16px;
            color:#F0F0F0;
            display: flex;
            justify-content: center;
            position: relative;
            letter-spacing: 2px;
            font-weight: 500;
        }
        .btn{
            font-size: 14px;
            color:#7F68FF;
            cursor: pointer;
            position: absolute;
            left: 0;
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        .button{
            background: #7F68FF;
            padding: 9px 20px;
            border-radius: 4px;
            height: auto;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
            color:#FFFFFF;
            letter-spacing: 2px;
            &:hover{
                background: #A190FF;
            }
            &:active{
                background: #6248F3;
            }
        }
    }
    .ant-form-item{
        background: #333333;
        border-radius: 4px;
        padding:6px 10px ;
        margin-bottom: 16px;
        border: 1px solid transparent;
        &:hover{
            border: 1px solid #a190ff;
        }
        &:focus-within{
            border: 1px solid #7f68ff;
        }
        .ant-form-item-label{
            text-align: start;
            padding: 0;
            >label{
                color:#AEADB4;
                &::after{
                    content:""
                }
            }
        }
    }
    .ant-input-outlined{
        background: transparent;
        border-width:0 !important;
        &:focus,&:focus-within{
            box-shadow: none;
        }
    }
    
    .btns{
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color:#AEADB4;
        >div{
            cursor: pointer;
            &:hover{
                color:#F0F0F0;
            }
            &:active{
                color:#76757A;
            }
        }
    }
    .ant-checkbox-wrapper{
        font-size: 12px;
        color:#AEADB4;
        margin-top: 8px;
        .link{
            color:#7F68FF;
        }
    }
    .form{
        padding-top: 13px;
        .footer{
            margin-top: 18px;
        }
    }
    .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled){
        background: transparent;
        box-shadow: none;
    }
    .ant-modal-close{
       color:#AEADB4; 
       background: #333333;
       border-radius: 50%;
       border-width: 600;
       &:hover{
          background: #4E4D4F;
       }
       &:active{
          background:  #2f2e30;
       }
       .anticon{
        font-size: 10px;
       }
    }
    .ant-btn-link{
        color:#7F68FF;
        &:not(:disabled):not(.ant-btn-disabled):hover{
            color:#7F68FF;
            opacity: .8;
        }
        
    }
    .ant-input-affix-wrapper .anticon.ant-input-password-icon{
        color:#AEADB4;
    }
    .ant-input::-webkit-input-placeholder{
        color:#AEADB4;
    }
}
