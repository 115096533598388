.receivelist-wrap{
    .receivelist-content{
        background: #222222;
        border-radius: 4px;
        padding: 16px 24px;
        padding-bottom: 32px;
        .filter-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-wrap{
                .types{
                    display: flex;
                    align-items: center;
                    .item{
                        padding: 6px 16px;
                        font-size: 14px;
                        cursor: pointer;
                        &.active,&:active{
                            background: #7f68ff;
                            border-radius: 16px;
                        }
                        &:hover{
                            background: #403D4D;
                            border-radius: 16px;
                        }
                    }
                }
            }
            .right-wrap{
                display: flex;
                align-items: center;
                gap: 16px;
                .filter{
                    background: url('./img/filter.png');
                    background-size: 100% 100%;
                    width: 24px;
                    height: 24px;
                }
                .right-button{
                    display: flex;
                    gap: 16px;
                    .btn{
                        padding-left: 22px;
                        padding-right: 22px;
                    }
                }
            }
        }
        .weapon-wrap{
            display: flex;
            flex-wrap: wrap;
            gap: 28px;
            margin-top: 16px;
            .item{
                width: 208px;
                .img-wrap{
                    height: 140px;
                    width: 100%;
                    background: url('./img/weapon-bg.png') no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 80%;
                    }
                }
                .weapon-info{
                    border:1px solid #4E4D4F;
                    border-radius:0 0 4px 4px;
                    border-top: none;
                    padding: 8px;
                    font-size: 14px;
                    p{padding: 0;margin: 0;}
                    .name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .price{
                        margin:4px 0;
                        >span{color: #7f68ff;line-height: 22px;font-size: 16px;}
                    }
                    .button{
                        background: #403d4d;
                        border-radius: 2px;
                        padding: 5px 0;
                        text-align: center;
                        cursor: pointer;
                    }
                }
                &:hover{
                    .weapon-info{
                        border-color: #7f68ff;
                        .button{
                            background: #7f68ff;
                        }
                    }
                }
            }
        }
        .page-wrap{
            margin-top: 32px;
            text-align: center;
        }
    }
}

@media (max-width:790px) {
    .receivelist-wrap{
        .receivelist-content{
            .filter-wrap{
                .right-wrap{
                    .input-wrap{
                        width: 90vw;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
            .weapon-wrap{
                gap: 12px;
                .item{
                    width: calc(33% - 8px);
                    .img-wrap{
                        height: 71px;
                    }
                    .weapon-info{
                        font-size: 10px;
                        padding: 4px;
                        .price{
                            margin: 2px 0;
                            >span{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .left-btns{
        .item{
            width: calc(33% - 8px);
            background: #333333;
            border-radius: 4px;
            text-align: center;
            padding: 8px 0;
            font-size: 12px;
            &.active{
                background: #403d4d;
                color:#7F68FF;
            }
        }
    }
}