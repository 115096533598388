.shop-wrap{
    background: #222222;
    .shoplist{
        .shop{
            display: flex;
            padding: 24px;
            border-bottom:1px solid #333333;
            justify-content: space-between;
            &:last-child{
                border-bottom: none;
            }
            .shop-info{
                >div{
                    display: flex;
                    gap: 12px;
                    font-size: 14px;
                    color: #f0f0f0;
                    h3,p{margin: 0;padding: 0;}
                    .image-wrap{
                        width: 104px;
                        height: 104px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    h3{
                        font-size: 14px;
                        font-weight: 600;
                        color: #f0f0f0;
                        letter-spacing: 2px;
                    }
                    .tip{
                        font-size: 12px; 
                        color: #aeadb4;
                        margin: 6px 0;
                    }
                    .discount{
                        margin: 9px 0 12px 0;
                        
                    }
                }
                .bottom{
                    margin-top: 12px;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #aeadb4;

                    .btn{
                        color:#8C8B90;
                        line-height: 17px;
                        font-size: 12px;
                        .link{background-image: url('./img/link.png');}
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        cursor: pointer;
                        &:hover{
                            .link{background-image: url("./img/link_hover.png");}
                            color:#7F68FF;
                        }

                    }
                }
                
            }
            .goods{
                display: flex;
                gap: 24px;
                align-items: center;
                .item{
                    width: 140px;
                    .image-wrap{
                        width: 100%;
                        height: 85px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: url("./img/item-bg.png") no-repeat;
                        background-size: 100% 100%;
                        img{
                            height: 90%;
                        }
                    }
                    .weapon-info{
                        border: 1px solid #4e4d4f;
                        border-radius: 2px;
                        font-size: 12px;
                        text-align: center;
                        p{
                            margin: 6px 0;
                            padding: 0;
                            letter-spacing: 1px;
                            color: #f0f0f0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            &.price{
                                font-size: 12px;
                                color:#AEADB4;
                                >span{
                                    font-size: 14px;
                                    color: #7F68FF; 
                                }
                            }
                        }
                    }
                }
                .more{
                    float: left;
                    cursor: pointer;
                }
            }
        }
    }
    .page-wrap{
        text-align: center;
        padding-bottom: 32px;
    }
}


.goods-list{
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;
    .item{
        width: 208px;
        .image-wrap{
            width: 100%;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("./img/item-bg.png") no-repeat;
            background-size: 100% 100%;
            img{
                height: 90%;
            }
        }
        .weapon-info{
            border: 1px solid #4e4d4f;
            border-radius: 2px;
            font-size: 12px;
            p{
                margin: 6px 0;
                padding: 0 8px;
                letter-spacing: 1px;
                color: #f0f0f0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.price{
                    font-size: 12px;
                    color:#AEADB4;
                    >span{
                        font-size: 14px;
                        color: #7F68FF; 
                    }
                }
            }
        }
    }
}