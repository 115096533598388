i.edit{
    background-image: url("./img/edit.png");
    background-size:100% 100% ;
    vertical-align: middle;
}
.input-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    color:#7F68FF;
    cursor: pointer;
}
i.renz{
    background-image: url("./img/renz.png");
}
i.link{
    background-image: url("./img/link.png");
}
i.time{
    background-image: url("./img/time.png");
}
.record-list{
    .item{
        border-bottom: 1px solid #333333;
        margin-bottom: 8px;
        .top{
            >div{
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
        >div{
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            p{
                margin: 8px 0;
            }
        }
    }
}
i.zfb{
    background-image: url("./img/zfb.png");
    width: 24px;
    height: 24px;
}

.txBtns{
    display: flex;
    .item{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color:#F0F0F0;
        background: #333333;
        border-radius: 4px;
        padding: 8px 16px;
        border: 1px solid transparent;
        position: relative;
        cursor: pointer;
        &.active{
            border-color:#7F68FF;
            &::after{
                content: "";
                position: absolute;
                background-image: url('./img/active.png');
                top:0;
                right: 0;
                width: 12px;
                height: 12px;
                background-size: 100% 100%;
            }
        }
    }
}

.info-item-m{
    color:#F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #333333;
    padding: 8px;
    margin: 0;
    border-radius: 4px;
    span{
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ant-tour{
    width: 270px;
    background: #7F68FF;
}

.ant-tabs .ant-tabs-ink-bar{
    height: 4px !important;
    width: 24px !important;
    border-radius: 100px 0px 100px 0px;
    margin-bottom: 8px;
}

.descript{
    border: 1px solid #333333;
    border-radius: 4px;
    background:#111111;
    padding:16px;
}
.form-tips{
    position: absolute;
    right: -200px;
    top:5px;
}