.order-wrap{
    .title{
        text-align: center;
        margin-top: 32px;
        margin-bottom: 12px;
    }
}
.order-list-wrap{
    display: flex !important;
    margin-top: 12px;
    flex-wrap: wrap;
    color:#F0F0F0;
    gap: 42px;
    .item{
        width: 206px;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        .time{
            position: absolute;
            top: 1;
            left: 1;
            background: rgba(51,51,51,0.60);
            border-radius: 4px 0px 4px 0px;
            font-size: 12px;
            padding: 3px 4px;
            text-align: center;
            min-width: 46px ;
            letter-spacing: 2px;
        }
        .top-wrap{
            width: 100%;
            height: 140px;
            background: url('./img/item-bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 4px 4px 0px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            .img-wrap{
                max-width: 80%;
                height: 80%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .weapon-info{
            padding: 8px;
            border: 1px solid #4e4d4f;
            border-top: 0px;
            border-radius: 0 0 4px 4px;
            p{
                padding:2px 0px;
                margin: 0;
            }
            .name{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .price{
                color:#7F68FF;
                .label{
                    color:#AEADB4;
                }
            }
        }
    }
}

@media (max-width:790px) {
    .order-wrap{
        margin-top: 8px;
        .title{
            margin-top: 12px;
            margin-bottom: 0;
        }
    }
    .order-list-wrap{
        padding: 15px;
        padding-top: 0px;
        gap: 12px;
        .item{
            width: calc(33% - 10px) ;
            .time{
                font-size: 10px;
                min-width: 36px;
            }
            .top-wrap{
                height: 70px;
            }
            .weapon-info{
                padding: 0px;
                font-size: 10px;
                >P{
                    margin: 4px;
                }
            }
        }
    }
}