.main-content{
    min-height: calc(100vh - 64px);
    padding: 16px 0;
}
.main-content .content{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.tools{
    background: #222222;
    border-radius: 22px;
    position: fixed;
    right:calc(50vw - 680px);
    top: 45%;
    padding: 17px 10px;
    writing-mode: vertical-rl;
    font-size: 14px;
    letter-spacing: 5px;
    cursor: pointer;
}
.tools:hover{
    background: #403d4d;
}
.tools:active{
    background: #333333;
    color:#76757A;
}
.tools .contact{
    background-image: url('./contact.png');
    margin-bottom:10px;
}
.tools:active .contact{
    background-image: url('./contact_active.png');
}
.ant-form-item-label >label::after{
    content: '' !important;
}

@media (max-width:790px) {
    .main-content .content{
        width: 100vw;
        margin: 0 auto;
    }
    .main-content{
        padding: 0;
    }
    .tools{
        background: #222222;
        position: fixed;
        right: 0;
        top: 80%;
        padding: 9px 10px;
        writing-mode:horizontal-tb;
        font-size: 14px;
        letter-spacing: 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        border: 1px solid #333333 ;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
        border-radius: 22px 0 0 22px;

    }
    .tools .contact{
        margin: 0;
    }
}



