.statistics-wrap{
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 64px;
    .item{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #222222;
        width: 33%;
        gap:12px;
        padding: 20px 0;
        border-radius: 4px;
        font-size: 14px;
        .image-wrap{
            width: 72px;
            height: 72px;
            img{
                width: 100%;
            }
        }
        .num{
            color:#7F68FF;
            font-weight: bold;
        }
    }
}
@media (max-width:790px) {
    .statistics-wrap{
        margin: 12px 15px;
        gap: 12px;
        margin-bottom: 24px;
        .item{
            flex-direction: column;
            font-size: 12px;
            text-align: center;
            padding: 8px 0;
            gap: 4px;
            p{padding: 0;margin: 6px 0;}
            .image-wrap{
                width: 32px;
                height: 32px;
            }
            .num{
                line-height: 20px;
                font-size: 14px;
            }
        }
    }
}