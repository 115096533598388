.notic-wrap{
    background: #333333;
    border-radius: 4px;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color:#f0f0f0;
    transition: all 0.5s ease-in-out;
    .left{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    i.warning{
        background-image: url("./img/notic.png");
    }
    i.close{
        background-image: url("./img/close.png");
        cursor: pointer;
    }
}