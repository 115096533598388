.m-order-wrap{
    .top-wrap{
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 15px;
        .filter{
            background: url('./img/filter.png');
            background-size: 100% 100%;
            width: 24px;
            height: 24px;
        }
    }
    .orderlist{
        padding:15px;
        padding-top: 8px;
        .item{
            background: #222222;
            padding: 5px 8px;
            border-radius: 4px;
            margin-bottom: 12px;
            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 8px 0;
                .time{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    i.timei{
                        background: url('./img/time.png');
                        background-size: 100% 100%;
                    }
                }
            }
            .content-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info{
                    display: flex;
                    font-size: 12px;
                    align-items: center;
                    gap: 5px;
                    .image-wrap{
                        width: 84px;
                        height: 56px;
                        background: url("./img/item-bg.png") no-repeat;
                        background-size: 100% 100%;
                        border-radius: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            height: 80%;
                        }
                    }
                }
                .right{
                    .buttons{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
                }
            }
        }
    }
    
}


.order-detail{
    padding: 8px 15px;
    .order-top{
        .weapon-item{
            background: #222222;
            border-radius: 4px;
            padding: 12px 8px;
            display: flex;
            align-items: center;
            gap: 8px;
            .image-wrap{
                width: 84px;
                height: 56px;
                background: url("./img/item-bg.png") no-repeat;
                background-size: 100% 100%;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: 90%;
                }
            }
        }
    }
    .order-info{
        background: #222222;
        border-radius: 4px;
        padding: 12px 8px;
        margin-top: 12px;
        .item{
            border-bottom: 1px solid #353437;
            padding: 24px 0;
            display: flex;
            justify-content: space-between;
            &:last-child{
                border: none;
            }
            .value{
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }
    .copy{
        background-image: url('./img/copy.png');
    }
}

.statistics{
    text-align: center;
    color: #AEADB4;
    font-size: 10px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.right-menu{
    .ant-drawer-header{
        border-bottom: 1px solid #333333;
        padding: 12px 0;
    }
    .ant-drawer-title{
        font-weight: 400;
        color:#F0F0F0;
        padding-left: 15px;
    }
    .ant-drawer-body{
        padding: 12px 15px;
    }
}
.left-btns{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .btn{
        width: calc(33% - 8px);
        background: #333333;
        border-color: transparent;
    }
}
.right-footer{
    display: flex;
    gap: 12px;
    .btn{
        width: 50%;
    }
}