.myTable{
    .ant-pagination{
        background: #222222;
        margin: 0 !important;
        padding: 16px 0;
    }
    .ant-table-wrapper .ant-table-thead >tr>th{
        padding: 10px 24px;
    }
}
.topTitle{
    background: url('./img/topbg.png') no-repeat;
    background-size: 100%;
    text-align: center;
    font-size: 12px;
    padding: 8px 0;
}
.form-wrap{
    background: #222222;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
        font-size: 14px;
    }
}