.banner-wrap{
    margin-top: 16px;
    .slick-dots-bottom{
        justify-content: flex-start;
        margin-bottom: 50px;
        margin-left:64px;
    }
}
@media (max-width:790px) {
    .banner-wrap{
        margin: 8px 15px;
        .slick-dots-bottom{
            justify-content: flex-start;
            margin-bottom: 19px;
            margin-left:16px;
        }
    }
}