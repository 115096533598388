.mtopBar{
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333333;
    .left{
        display: flex;
        align-items: center;
        gap:7px;
        .logo-wrap{
            height: 44px;
           
            img{
                height: 100%;
            }
        }
        i.menu{
            background-image: url('./img/menu.png');
        }
    }
    .right{
        display: flex;
        align-items: center;
    }
}
.menus{
    .item{
        padding: 14px;
        display: flex;
        align-items: center;
        i.center{
            background-image: url("./img/icon/center.png");
        }
        gap: 10px;
        &.active{
            background: linear-gradient(270deg,rgba(13,13,13,0.00) 60%, rgba(121,97,255,0.40));
            color:#7F68FF;
            font-weight: 600;
            i.index{background-image: url("./img/icon/index_active.png");}
            i.order{background-image: url("./img/icon/order_active.png");}
            i.invite{background-image: url("./img/icon/invite_active.png");}
            i.notic{background-image: url("./img/icon/notic_active.png");}
            i.center{background-image: url("./img/icon/center_active.png");}
            i.receivelist{background-image: url("./img/icon/receivelist_active.png");}
            i.shop{background-image: url("./img/icon/shop_active.png");}
        }
    }
}
.userinfo{
    display: flex;
    align-items: center;
    font-size: 12px;
    .left{
        display: flex;
        flex-direction: column;
        gap: 0;
        >p{
            margin: 0;
        }
    }
}
.left-menu {
    .ant-drawer-content{
        background: #111111;
    }
    .ant-drawer-header{
        background: url("./img/top-bg.png") no-repeat;
        background-size: 100% 100%;
        padding: 0;
        padding-left: 15px;
    }
    .ant-drawer-body{
        background: url("./img/content-bg.png") no-repeat;
        background-size: 100% 100%;
    }
}